/* App.css */

/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  scroll-behavior: smooth;
}

/* Navigation Styles */
nav {
  background-color: #2c3e50;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 1rem 0;
}

nav ul li {
  margin: 0 1.5rem;
}

nav ul li a {
  color: #ecf0f1;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #3498db;
}

/* Section Styles */
.section {
  padding: 0 20px; /* Removed top and bottom padding */
  min-height: 100vh;
}

.section + .section {
  padding-top: 0; /* Removed top padding from subsequent sections */
}

/* Add top padding to first section to account for fixed nav */
.section:first-of-type {
  padding-top: 150px; /* Adjust this value if necessary */
}

.home-section {
  background: linear-gradient(
      rgba(44, 62, 80, 0.8),
      rgba(44, 62, 80, 0.8)
    ),
    url('../public/897fc49983580ffca4a4103ae35e3067.jpg') center/cover no-repeat;
  color: #ecf0f1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.home-section p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto 1.5rem;
}

.projects-section {
  background-color: #ecf0f1;
}

.projects-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #2c3e50;
}

.projects-section ul {
  max-width: 1000px;
  margin: 0 auto;
  list-style: none;
}

.projects-section li {
  background-color: #fff;
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.projects-section h3 {
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.projects-section p {
  margin-bottom: 1rem;
}

.projects-section a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.projects-section a:hover {
  text-decoration: underline;
}

/* Merged About & Contact Section */
.about-contact-section {
  background-color: #fff;
  color: #2c3e50;
  padding-bottom: 50px; /* Add some bottom padding */
}

.about-contact-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #2c3e50;
}

.about-contact-section p {
  max-width: 800px;
  margin: 0 auto 1.5rem;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: left;
}

.about-contact-section a {
  color: #3498db;
  text-decoration: none;
}

.about-contact-section a:hover {
  text-decoration: underline;
}

/* Removed form styles as the form is no longer present */

/* Footer Styles */
footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 1rem 0;
  text-align: center;
}

footer p {
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  nav ul li {
    margin: 0 1rem;
  }

  .home-section h1 {
    font-size: 2.5rem;
  }

  .home-section p {
    font-size: 1rem;
  }

  .projects-section h2,
  .about-contact-section h2 {
    font-size: 2rem;
  }
}


.tryhackme-badge {
  max-width: 300px;
  margin: 2rem auto;
  text-align: center;
}

.tryhackme-badge img {
  max-width: 100%;
  height: auto;
}

/* Contact Icons Styles */
.contact-icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem 0;
}

.contact-icons a {
  color: #2c3e50;
  transition: color 0.3s ease;
}

.contact-icons a:hover {
  color: #3498db;
}